import { AtlasHtml, AtlasLoading } from "atlas-ds";
import classNames from "classnames";

export interface AtlasValueProps {
  /**
   * Le label
   */
  label: string;
  /**
   * La valeur
   */
  value: number | string | React.ReactNode | { html: string };
  /**
   * Placer le label et la valeur sur la même ligne, si possible.
   * Éviter d'utiliser uniquement pour gagner de la place.
   * Réserver pour pour des cas particuliers (comme une série de montants qui
   * seront ainsi alignés à droite).
   * Cette propriété est automatiquement active lorsqu'il y a des sous-valeurs
   * (children).
   */
  inline?: boolean;
  /**
   * Des sous-valeurs associées (par exemple le détail d'un total)
   * Utiliser <AtlasValue> en tant qu'enfant(s) de lui-même dans ce cas.
   */
  children?: React.ReactNode;
}

/**
 * Une valeur associée à un label
 */
export function AtlasValue(props: AtlasValueProps) {
  return (
    <div
      className={classNames("atlas-value", {
        "atlas-value--inline": props.inline || props.children,
      })}
    >
      <div className="atlas-value__inner">
        <div className="atlas-value__label">{props.label}</div>
        <AtlasLoading.Loader />

        <div className="atlas-value__value">
          {props.value &&
          typeof props.value === "object" &&
          "html" in props.value ? (
            <AtlasHtml>{props.value.html}</AtlasHtml>
          ) : (
            props.value
          )}
        </div>
      </div>

      {props.children}
    </div>
  );
}
