import { AtlasIcon } from "atlas-ds/atlas/components/image";
import classNames from "classnames";
import { useRef, useState } from "react";
import { AtlasCheckbox, AtlasCheckboxProps } from "../../AtlasCheckbox";
import { AtlasFieldBaseProps, AtlasFieldInternal } from "../AtlasFieldInternal";

export interface AtlasFieldCheckboxDropdownProps extends AtlasFieldBaseProps {
  /**
   * Les options disponibles
   */
  options: Omit<AtlasCheckboxProps, "name" | "onChange">[];
  /**
   * Faut-il séparer la première option des autres visuellement ?
   */
  separateFirstOption?: boolean;
}

/**
 * Une liste déroulante de cases à cocher
 */
export function AtlasFieldCheckboxDropdown(
  props: AtlasFieldCheckboxDropdownProps
) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLFieldSetElement>(null);

  const onBlur = (event: React.FocusEvent) => {
    if (!ref.current?.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };

  return (
    <fieldset
      ref={ref}
      id={props.name}
      className="atlas-fieldCheckboxDropdown"
      aria-invalid={props.error ? "true" : undefined}
      aria-describedby={props.error ? `${props.name}-error` : undefined}
      tabIndex={-1}
      disabled={props.disabled}
      onBlur={onBlur}
    >
      <legend>
        {props.label} {props.instructions}
      </legend>
      <AtlasFieldInternal {...props} isFieldset>
        <button
          className="atlas-fieldCheckboxDropdown__cta"
          aria-expanded={isOpen}
          aria-controls={`${props.name}-options`}
          onClick={() => setIsOpen(!isOpen)}
        >
          Sélectionner
          <AtlasIcon name="arrow-down" size="xs" />
        </button>

        <div
          className="atlas-fieldCheckboxDropdown__options"
          id={`${props.name}-options`}
        >
          {props.options.map((option, index) => (
            <div
              className={classNames("atlas-fieldCheckboxDropdown__option", {
                "atlas-fieldCheckboxDropdown__option--separator":
                  index === 0 && props.separateFirstOption,
              })}
              key={`${props.name}-${option.value}`}
            >
              <AtlasCheckbox
                onChange={props.onChange}
                {...option}
                name={props.name}
                inputConstructor={props.inputConstructor}
              />
            </div>
          ))}
        </div>
      </AtlasFieldInternal>
    </fieldset>
  );
}
