import { AtlasButton, AtlasHeading, AtlasTagsProps } from "atlas-ds";
import { MouseEventHandler } from "react";

export interface AtlasResultsHeaderProps {
  /**
   * Le nombre de résultats
   */
  count: number;
  /**
   * La liste des filtres actifs
   */
  filters?: React.ReactElement<AtlasTagsProps>;
  /**
   * Action à exécuter lorsque les filtres sont retirés
   */
  onResetFilters?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Un composant de tri
   */
  sortOptions?: React.ReactNode;
  /**
   * Un label spécial pour les résultats (à la place de "résultat"),
   * au singulier
   */
  resultsLabel?: string;
}

/**
 * Composant de présentation de résultats : nombre d'éléments trouvés, filtres
 * appliqués, options de tri...
 */
export function AtlasResultsHeader(props: AtlasResultsHeaderProps) {
  const resultsLabel = props.resultsLabel || "résultat";

  return (
    <header className="atlas-resultsHeader">
      <div className="atlas-resultsHeader__main">
        <div className="atlas-resultsHeader__count" aria-live="polite">
          <AtlasHeading
            tag="h2"
            size="m"
            heading={
              props.count === 0
                ? `Aucun ${resultsLabel}`
                : `${props.count ?? ""} ${resultsLabel}${
                    (props.count ?? 0) > 1 ? "s" : ""
                  }`
            }
          />
        </div>

        {props.filters && (
          <div className="atlas-resultsHeader__resetFilters">
            <AtlasButton
              label="Réinitialiser les filtres"
              icon="filter"
              level={4}
              onClick={props.onResetFilters}
            />
          </div>
        )}

        {props.sortOptions}
      </div>

      {props.filters && (
        <div className="atlas-resultsHeader__filters">{props.filters}</div>
      )}
    </header>
  );
}
