import classNames from "classnames";

export interface AtlasColumnsProps {
  /**
   * Les colonnes de contenu
   */
  children: React.ReactNode;
  /**
   * Inverser les proportions des colonnes
   */
  reversed?: boolean;
}

/**
 * Conteneur permettant de séparer le contenu en deux colonnes
 * (2 tiers / 1 tiers)
 */
export function AtlasColumns(props: AtlasColumnsProps) {
  return (
    <div
      className={classNames("atlas-columns", {
        "atlas-columns--reversed": props.reversed,
      })}
    >
      {props.children}

      {/* Empty div when children is alone */}
      {!Array.isArray(props.children) && (
        <div className="atlas-columns__placeholder"></div>
      )}
    </div>
  );
}
