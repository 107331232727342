import { AtlasIcon, AtlasLinkConstructor } from "atlas-ds";
import classNames from "classnames";
import { MouseEventHandler } from "react";

export interface AtlasLinkProps {
  /**
   * Label affiché
   */
  label: string;
  /**
   * La cible du lien
   */
  href: string;
  /**
   * Identifiant du lien
   */
  id?: string;
  /**
   * Le niveau d'importance visuelle du lien (1 est le plus élevé)
   */
  level?: 1 | 2 | 3 | 4;
  /**
   * L'icône à afficher au sein du lien
   */
  icon?: string;
  /**
   * Le lien pointe-t-il vers l'extérieur du site ? Si oui, les attributs
   * `rel="noopener noreferrer" et `target="_blank"` seront ajoutés.
   */
  external?: boolean;
  /**
   * Si un simple lien ne suffit pas, un constructeur de lien
   */
  linkConstructor?: AtlasLinkConstructor;
  /**
   * Action à exécutée en supplément
   */
  onClick?: MouseEventHandler;
  /**
   * Lier visuellement le bouton à l'identité Atlas
   */
  atlasBrand?: boolean;
  /**
   * La description accessible du lien. Attention, ce texte remplacera le
   * contenu du lien pour les technologies d'assistance. À n'utiliser que s'il
   * est impossible de rendre le label du lien suffisamment explicite.
   */
  ariaLabel?: string;
  /**
   * Désactiver le lien.
   * Les liens désactivés n'existent pas vraiment. À utiliser avec parcimonie,
   * par exemple pour désactiver un lien le temps d'une soumission.
   * Voir https://www.scottohara.me/blog/2021/05/28/disabled-links.html
   */
  disabled?: boolean;
}

/**
 * Un lien menant vers une autre page du site ou externe au site
 */
export function AtlasLink(props: AtlasLinkProps) {
  const linkProps = {
    href: props.disabled ? undefined : props.href,
    className: classNames(
      "atlas-link",
      `atlas-link--level${props.level || "1"}`,
      {
        "atlas-link--hasIcon": props.icon,
        "atlas-link--atlasBrand": props.atlasBrand,
      }
    ),
    rel: props.external ? "noopener noreferrer" : undefined,
    target: props.external ? "_blank" : undefined,
    onClick: props.disabled ? undefined : props.onClick,
    "aria-label": props.ariaLabel,
    "aria-disabled": props.disabled ? true : undefined,
  };

  const linkContent = (
    <>
      {props.icon && <AtlasIcon name={props.icon} />}
      {props.label}
    </>
  );

  if (props.linkConstructor && !props.disabled) {
    return props.linkConstructor(linkProps, linkContent);
  }

  return <a {...linkProps}>{linkContent}</a>;
}
