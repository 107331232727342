import {
  AtlasFieldBaseProps,
  AtlasFieldInternal,
  AtlasIcon,
  AtlasIconButton,
} from "atlas-ds";
import { format, parse } from "date-fns";
import { fr } from "date-fns/locale";
import { useEffect, useRef, useState } from "react";
import {
  DateRange,
  DayPicker,
  SelectRangeEventHandler,
} from "react-day-picker";
import "react-day-picker/dist/style.css";
import ReactInputMask from "react-input-mask";
import { usePopper } from "react-popper";

// Implementation details
// https://react-day-picker.js.org/guides/input-fields#example-range-selection
// https://react-day-picker.js.org/guides/input-fields#example-date-picker-dialog

// Accessibilité de react-day-picker
// *********************************
// À l'origine choisie car étant une des seules librairies de ce type assez
// bien implémentée niveau accessibilité.
// Malheureusement bugs d'accessibilité au delà de la version 8.4.0, donc
// ne pas utiliser une version supérieure pour le moment (la version à date
// est 8.8.0).
// En attente de la version 9 pour mettre à jour.

export interface AtlasFieldPeriodPreset {
  /**
   * Le nom de la plage pré-établie
   */
  label: string;
  /**
   * Les valeurs qui seront sélectionnées
   */
  values: [Date, Date];
}

export interface AtlasFieldPeriodProps
  extends AtlasFieldBaseProps<HTMLInputElement, any> {
  /**
   * Les dates sélectionnées
   */
  value: [Date?, Date?];
  /**
   * Un texte de placeholder affiché avant la sélection
   */
  placeholder?: string;
  /**
   * La date minimale
   */
  min?: Date;
  /**
   * La date maximale
   */
  max?: Date;
  /**
   * Une ou plusieurs plage de dates pré-établies
   */
  presets?: AtlasFieldPeriodPreset[];
  /**
   * L'action à éxécuter lors du changement de période
   */
  onPeriodChange: (dates: [Date, Date]) => void;
}

/**
 * Un champ de sélection d'une période.
 */
export function AtlasFieldPeriod(props: AtlasFieldPeriodProps) {
  // Don't show inputMask on server side generated content, this
  // triggers warnings
  const [isClientSide, setIsClientSide] = useState(false);
  useEffect(() => setIsClientSide(true), []);

  // Convert two dates to "10/02/2022 ~ 11/01/2023" style date
  const getInputString = (from: Date, to: Date) => {
    return `${format(from, "dd/MM/yyyy")} ~ ${format(to, "dd/MM/yyyy")}`;
  };

  const ref = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [selectedRange, setSelectedRange] = useState<DateRange>();
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popperRef = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  // Set error if , and return the validity
  const validateInputString = (from: Date, to: Date): boolean => {
    if (!from.getDate()) {
      setError("La date de début est invalide");
      return false;
    }

    if (!to.getDate()) {
      setError("La date de fin est invalide");
      return false;
    }

    if (props.min && from < props.min) {
      setError(
        `La date de début doit être au minimum ${format(
          props.min,
          "dd/MM/yyyy"
        )}`
      );
      return false;
    }

    if (props.max && to > props.max) {
      setError(
        `La date de début doit être au maximum ${format(
          props.max,
          "dd/MM/yyyy"
        )}`
      );
      return false;
    }

    if (from > to) {
      setError("La date de début est postérieure à la date de fin");
      return false;
    }

    setError("");
    return true;
  };

  useEffect(() => {
    if (props.value[0] && props.value[1]) {
      validateInputString(props.value[0], props.value[1]);
      setInputValue(getInputString(props.value[0], props.value[1]));

      // On values reset, empty the calendar range selection
      if (
        props.value[0].toDateString() === props.min?.toDateString() &&
        props.value[1].toDateString() === props.max?.toDateString()
      ) {
        setSelectedRange({ from: undefined, to: undefined });
      } else {
        setSelectedRange({ from: props.value[0], to: props.value[1] });
      }
    }
  }, [props.value]);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-start",
  });

  // On calendar selection
  const handleRangeSelect: SelectRangeEventHandler = (
    range: DateRange | undefined
  ) => {
    setSelectedRange(range);
  };

  // On input change
  const onChange = (e: any) => {
    setInputValue(e.target.value);

    // Stop if both dates are not set yet
    if (!e.target.value || e.target.value.includes("_")) {
      setError("");
      return;
    }

    const [from, to] = e.target.value
      .split(" ~ ")
      .map((date: string) => parse(date, "dd/MM/yyyy", new Date()));

    if (validateInputString(from, to)) {
      setSelectedRange({ from, to });

      if (props.onPeriodChange) {
        props.onPeriodChange([from, to]);
      }
    }
  };

  // Return french label for date (1er mai 2022, 2 mai 2022...)
  const getLabelDay = (day: Date): string => {
    const dayOfMonth = day.getDate() === 1 ? "1er" : day.getDate();
    const month = new Intl.DateTimeFormat("fr-FR", {
      month: "long",
      year: "numeric",
    }).format(day);

    // Adapt the label to the current selection state and to the plugin behavior
    let prefix = "jusqu'au";

    if (
      selectedRange?.from &&
      selectedRange.from.toDateString() === day.toDateString() &&
      selectedRange.to
    ) {
      // Clicking on the from date if both date are set will cancel the selection
      prefix = "annuler la sélection";
    } else if (
      !selectedRange?.from ||
      selectedRange.from > day ||
      selectedRange.to?.toDateString() === day.toDateString()
    ) {
      // Clicking on a date that's after the "from" date or that is the current "to" date
      // will set the from date
      prefix = "à partir du";
    }

    return `${prefix} ${dayOfMonth} ${month}`;
  };

  const onFocus = () => {
    setIsPopperOpen(true);
  };

  const onBlur = (event: React.FocusEvent) => {
    if (!ref.current?.contains(event.relatedTarget)) {
      setIsPopperOpen(false);
    }
  };

  const onSubmit = (from: Date, to: Date) => {
    setError(""); // A range submitted via the calendar is always valid
    setIsPopperOpen(false);

    setInputValue(getInputString(from, to));
    props.onPeriodChange([from, to]);
  };

  const onCalendarClick = () => {
    setIsPopperOpen(!isPopperOpen);
  };

  return (
    <AtlasFieldInternal {...props} error={props.error ?? error}>
      <div className="atlas-fieldPeriod" ref={ref} onBlur={onBlur}>
        <div className="atlas-fieldPeriod__input">
          {isClientSide ? (
            <ReactInputMask
              mask="99/99/9999 ~ 99/99/9999"
              value={inputValue}
              onChange={onChange}
              onFocus={onFocus}
              placeholder={props.placeholder}
              disabled={props.disabled}
              aria-invalid={!!(error || props.error)}
              aria-describedby={
                !!(error || props.error) ? `${props.name}-error` : undefined
              }
            >
              <input name={props.name} />
            </ReactInputMask>
          ) : (
            <input name={props.name}></input>
          )}
        </div>

        <div className="atlas-fieldPeriod__button">
          <AtlasIconButton
            ariaLabel="Calendrier de sélection de période"
            ariaControls={`${props.name}-popper`}
            ariaExpanded={isPopperOpen}
            onClick={onCalendarClick}
          >
            <AtlasIcon name="calendar" size="s" />
          </AtlasIconButton>
        </div>

        {isPopperOpen && (
          <div
            id={`${props.name}-popper`}
            tabIndex={-1}
            style={popper.styles.popper as any}
            className="atlas-fieldPeriod__dialog"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
            aria-label="Calendrier de sélection de période"
          >
            <DayPicker
              mode="range"
              numberOfMonths={
                window.matchMedia("(min-width: 80em)").matches ? 2 : 1
              }
              selected={selectedRange}
              onSelect={handleRangeSelect}
              locale={fr}
              fromDate={props.min}
              toDate={props.max}
              labels={{
                labelDay: getLabelDay,
                labelPrevious: () => "Mois précédent",
                labelNext: () => "Mois suivant",
              }}
            />

            <div className="atlas-fieldPeriod__footer">
              {props.presets && (
                <div className="atlas-fieldPeriod__presets">
                  {props.presets.map((preset) => (
                    <button
                      key={preset.label}
                      className="atlas-fieldPeriod__preset"
                      onClick={() => onSubmit(...preset.values)}
                    >
                      {preset.label}
                    </button>
                  ))}
                </div>
              )}

              <button
                className="atlas-fieldPeriod__submit"
                onClick={() => {
                  if (selectedRange?.from && selectedRange?.to) {
                    onSubmit(selectedRange.from, selectedRange.to);
                  }
                }}
                disabled={!selectedRange?.from || !selectedRange?.to}
              >
                Valider
              </button>
            </div>
          </div>
        )}
      </div>
    </AtlasFieldInternal>
  );
}
