
export interface CampusCertificationProps {
  /**
   * Le nom de la certification
   */
  label: string;
}

/**
 * Un badge de certification
 */
export function CampusCertification(props: CampusCertificationProps) {
  return <div className="campus-certification">
    { props.label }
  </div>
}
