import React from "react"

export type AtlasLinkConstructor = (
  props: any,
  content: React.ReactNode,
  index?: number
) => JSX.Element;

export const defaultLinkConstructor: AtlasLinkConstructor = (
  props: any,
  content: React.ReactNode,
): JSX.Element => <a {...props}>{content}</a>;
