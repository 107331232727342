import { AtlasIcon, AtlasTooltip } from "atlas-ds";

export interface CampusCardTagProps {
  /**
   * Le label
   */
  label: string;
  /**
   * Le contenu de la tooltip
   */
  tooltipContent?: React.ReactNode;
}

/**
 * Un tag destiné à enrichir une card
 */
export function CampusCardTag(props: CampusCardTagProps) {
  const content = (
    <div className="campus-cardTag__inner">
      {props.label}

      {props.tooltipContent && <AtlasIcon name="info" size="xs" />}
    </div>
  );

  return (
    <div className="campus-cardTag">
      {props.tooltipContent ? (
        <AtlasTooltip content={props.tooltipContent}>{content}</AtlasTooltip>
      ) : (
        content
      )}
    </div>
  );
}
