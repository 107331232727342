import { AtlasTooltip } from "atlas-ds";
import classNames from "classnames";
import React, { ChangeEventHandler, ForwardedRef, forwardRef } from "react";

export interface AtlasCheckboxInputProps {
  ref: ForwardedRef<any>;
  type: "checkbox";
  id: string;
  name: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  "aria-invalid"?: boolean;
  "aria-describedby"?: string;
}

export interface AtlasCheckboxProps {
  /**
   * Le nom technique de la checkbox
   * (identique au sein d'un groupe de checkboxes)
   */
  name: string;
  /**
   * La valeur associée à la checkbox
   */
  value: string;
  /**
   * Le label affiché à l'utilisateur
   */
  label: React.ReactNode;
  /**
   * Un label plus précis pour les technologies d'assistance
   */
  ariaLabel?: string;
  /**
   * La checkbox doit-elle être cochée obligatoirement ?
   */
  required?: boolean;
  /**
   * La checkbox est-elle déjà cochée ?
   */
  checked?: boolean;
  /**
   * La checkbox est-elle desactivée ?
   */
  disabled?: boolean;
  /**
   * Afficher la checkbox en taille réduite
   */
  mini?: boolean;
  /**
   * Un lien à afficher à côté de la checkbox
   */
  link?: React.ReactNode;
  /**
   * Une bulle d'aide à afficher à côté de la checkbox
   */
  tooltipContent?: React.ReactNode;
  /**
   * La checkbox est-elle dans un état invalide (cas d'une checkbox obligatoire
   * non cochée)
   */
  ariaInvalid?: boolean;
  /**
   * L'identifiant de l'élément décrivant l'erreur associée à la checkbox
   */
  ariaDescribedBy?: string;
  /**
   * Action à éxécuter lorsque la checkbox change d'état
   */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /**
   * Si un input natif ne convient pas, une fonction permettant d'en
   * construire un.
   */
  inputConstructor?: (props: AtlasCheckboxInputProps) => JSX.Element;
}

/**
 * Une case à cocher. Son usage direct est à distinguer de l'usage au sein d'un
 * formulaire, pour lequel `AtlasFieldCheckbox` sera généralement plus
 * approprié.
 */
export const AtlasCheckbox = forwardRef(function AtlasCheckbox(
  props: AtlasCheckboxProps,
  forwardedRef: ForwardedRef<any>
) {
  const inputProps: AtlasCheckboxInputProps = {
    ref: forwardedRef,
    type: "checkbox",
    id: `${props.name}-${props.value}`,
    name: props.name,
    value: props.value,
    onChange: props.onChange,
    checked: props.checked,
    disabled: props.disabled,
    required: props.required,
    "aria-invalid": props.ariaInvalid,
    "aria-describedby": props.ariaDescribedBy,
  };

  const input = props.inputConstructor ? (
    props.inputConstructor(inputProps)
  ) : (
    <input {...inputProps} />
  );

  return (
    <div
      className={classNames("atlas-checkbox", {
        "atlas-checkbox--mini": props.mini,
      })}
    >
      <div className="atlas-checkbox__inner">
        {input}

        <label htmlFor={inputProps.id} aria-label={props.ariaLabel}>
          {props.label}
          {props.required && <span aria-hidden="true"> *</span>}
        </label>

        {props.tooltipContent && (
          <div className="atlas-checkbox__tooltip">
            <AtlasTooltip
              content={props.tooltipContent}
              title={`Plus d'informations sur l'option ${props.label}`}
            />
          </div>
        )}

        {props.link && <div className="atlas-checkbox__link">{props.link}</div>}
      </div>
    </div>
  );
});
