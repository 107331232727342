
/*
 * Le logo campusAtlas
 */
export const AtlasCampusLogo = () => {
  return <svg
    className="atlas-campusLogo"
    aria-hidden="true"
    viewBox="15 28 349 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M285.13 62.31c-3.35 0-3.93-1.76-3.93-3.93h-7.86v1.49a10.471 10.471 0 0 0 10.33 10.32h5.39v-7.88h-3.93ZM301.7 27.53h-8v42.6h8v-42.6Zm6.55 34.55c0 4.62 3.85 8.89 10.16 8.89a9.9 9.9 0 0 0 8.71-4.39 20.21 20.21 0 0 0 .3 3.55h7.33a33.022 33.022 0 0 1-.37-4.69V50.9c0-5.95-3.48-11.23-12.85-11.23-7.93 0-12.2 5.11-12.68 9.73l7.09 1.5c.24-2.58 2.16-4.8 5.65-4.8s5 1.74 5 3.84c0 1-.55 1.86-2.23 2.1l-7.27 1.09c-4.92.72-8.83 3.66-8.83 8.95h-.01ZM320.09 65c-2.59 0-3.85-1.68-3.85-3.43 0-2.28 1.62-3.42 3.67-3.72l6.67-1v1.32c0 5.23-3.13 6.85-6.49 6.85V65Zm19.82-3.1c.36 3.36 3.42 9.13 12.32 9.13C360 71 363.7 66.1 363.7 61.3c0-4.33-2.94-7.87-8.77-9.08l-4.21-.9c-1.62-.3-2.7-1.2-2.7-2.64 0-1.68 1.68-2.94 3.79-2.94a4.502 4.502 0 0 1 4.86 4l6.67-1.5c-.36-3.19-3.18-8.53-11.6-8.53-6.36 0-11.05 4.38-11.05 9.67 0 4.15 2.58 7.57 8.29 8.83l3.91.9c2.28.48 3.18 1.56 3.18 2.89 0 1.56-1.26 2.94-3.9 2.94-3.49 0-5.23-2.16-5.41-4.51l-6.85 1.47ZM260.37 70.13h9l-16.22-42.6h-9.61l-16.34 42.6h8.8l3.54-9.74h17.3l3.53 9.74Zm-12.2-33.41 5.89 16.1h-11.78l5.89-16.1ZM289.09 39.6h-7.86v-7.86h-7.86v7.86h-7.86v7.86h7.86v7.86h7.86v-7.86h7.86V39.6Z"
      fill="#521E3E"
    />
    <path
      d="M37.07 58.73a6.76 6.76 0 0 1-6.78 5c-4.08 0-7.56-3-7.56-8.34s3.42-8.28 7.5-8.28a6.44 6.44 0 0 1 6.54 5l7.14-2.4c-1.26-5-5.82-10-13.86-10-8.58 0-15.3 6.54-15.3 15.66A15.27 15.27 0 0 0 30.29 71c7.86 0 12.48-5 13.8-10l-7.02-2.27ZM73.47 65.45V50.93c0-5.93-3.47-11.22-12.84-11.22-7.92 0-12.18 5.1-12.66 9.72l7.08 1.5c.24-2.58 2.16-4.8 5.64-4.8 3.48 0 5 1.74 5 3.84 0 1-.54 1.86-2.22 2.1l-7.26 1.08c-4.92.72-8.82 3.66-8.82 8.94 0 4.62 3.84 8.88 10.14 8.88a9.87 9.87 0 0 0 8.7-4.38 20.003 20.003 0 0 0 .3 3.54h7.32a33.068 33.068 0 0 1-.38-4.68Zm-7.8-7.26c0 5.22-3.12 6.84-6.48 6.84-2.58 0-3.84-1.68-3.84-3.42 0-2.28 1.62-3.42 3.66-3.72l6.66-1v1.3ZM114.53 39.77a10.26 10.26 0 0 0-9.36 4.92c-1.5-3.18-4.74-4.92-8.76-4.92-3.24 0-7 1.56-8.64 4.44v-3.6h-7.62v29.52h8V52.85c0-3.24 2-5.82 5.52-5.82 3.52 0 5.22 2.4 5.22 5.58v17.52h7.92V52.85c0-3.18 2-5.82 5.46-5.82 3.66 0 5.22 2.4 5.22 5.58v17.52h7.74V51c-.02-7.93-5.23-11.23-10.7-11.23ZM148.86 40a12.341 12.341 0 0 0-6 1.46v7.17a7.07 7.07 0 0 1 4.46-1.49c4.08 0 7.32 3.06 7.32 8.28 0 5.22-3.24 8.34-7.32 8.34a7.09 7.09 0 0 1-4.46-1.51v7.36a12.362 12.362 0 0 0 5.72 1.29c8.34 0 13.92-6.6 13.92-15.54-.02-8.81-5-15.36-13.64-15.36Zm-16.8.66v40.87h7.73V40.61l-7.73.05ZM194.39 64.79V40.61h-8v17.16c0 3.42-2 5.82-5.57 5.82-3.72 0-5.4-2.64-5.4-5.94v-17h-8v18.62c0 6.42 4.08 11.64 11.22 11.64 3.12 0 6.54-1.2 8.15-4a20.153 20.153 0 0 0 .24 3.18h7.62c-.183-1.76-.27-3.53-.26-5.3ZM214.39 52.25l-4.2-.9c-1.62-.3-2.7-1.2-2.7-2.64 0-1.68 1.68-2.94 3.78-2.94a4.502 4.502 0 0 1 4.86 4l6.66-1.5c-.36-3.18-3.18-8.52-11.58-8.52-6.36 0-11 4.38-11 9.66 0 4.14 2.58 7.56 8.28 8.82l3.9.9c2.28.48 3.18 1.56 3.18 2.88 0 1.56-1.26 2.94-3.9 2.94-3.48 0-5.22-2.16-5.4-4.5l-6.84 1.5c.36 3.36 3.42 9.12 12.3 9.12 7.74 0 11.46-4.92 11.46-9.72-.04-4.35-2.98-7.9-8.8-9.1Z"
      fill="#FF43B9"
    />
  </svg>
}
