import classNames from "classnames";

export interface AtlasTextProps {
  /**
   * Le texte
   */
  children: React.ReactNode;
  /**
   * Afficher le texte en gras
   */
  bold?: boolean;
  /**
   * Afficher le texte en petit
   */
  small?: boolean;
  /**
   * Afficher dans une couleur associée à un état.
   * Accessibilité : attention à toujours vérifier le niveau de contraste en
   * résultant (niveau AA exigé).
   */
  color?: "positive" | "negative";
  /**
   * Ajouter une couleur de fond, qui dépendra automatiquement du pararamètre
   * color (ou sera neutre)
   */
  background?: boolean;
  /**
   * Information complémentaire s'affichant au survol (attribut title natif)
   */
  title?: string;
}

/**
 * Ce composant permet d'appliquer certains styles élémentaires à un texte
 */
export function AtlasText(props: AtlasTextProps) {
  return (
    <span
      title={props.title}
      className={classNames("atlas-text", {
        "atlas-text--bold": props.bold,
        "atlas-text--small": props.small,
        "atlas-text--background": props.background,
        "atlas-text--positive": props.color === "positive",
        "atlas-text--negative": props.color === "negative",
      })}
    >
      {props.children}
    </span>
  );
}
