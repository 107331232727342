import { CampusHeading } from "atlas-ds";
import clesEnMainImg from "../../../assets/img/landing/cles_en_main.webp";
import employeurImg from "../../../assets/img/landing/employeur.webp";
import priseEnChargeImg from "../../../assets/img/landing/prise_en_charge.webp";
import salarieImg from "../../../assets/img/landing/salarie.webp";

export interface CampusLandingProps {
  /**
   * Le lien vers la page d'accueil
   */
  homeLink: React.ReactNode;
}

/**
 * La page de présentation campusAtlas
 */
export function CampusLanding(props: CampusLandingProps) {
  return (
    <div className="campus-landing">
      <div className="campus-landing__intro">
        <div className="campus-landing__container">
          <CampusHeading tag="h1" heading="Les meilleures formations aux meilleurs tarifs" />
          <p className="campus-landing__introText">
            Nos actions clés en main sont des actions de formation dont le
            besoin a été identifié comme prioritaire par votre branche
            professionnelle. Voici quelques bonnes raisons d’opter pour ce
            catalogue spécifique de formation.
          </p>
          {props.homeLink}
        </div>
      </div>

      <div className="campus-landing__container">
        <section className="campus-landing__part">
          <div>
            <CampusHeading
              tag="h2"
              heading="Les formations clés en main proposées par Atlas, c’est quoi&nbsp;?"
            />
            <p>
              Les actions de formation clés en main sont des formations
              identifiées par les partenaires sociaux de votre branche
              professionnelle pour répondre aux besoins de votre entreprise et à
              ceux de vos salariés.
            </p>
          </div>
          <img
            aria-hidden="true"
            alt=""
            loading="lazy"
            src={(clesEnMainImg as unknown as {src: string}).src ?? clesEnMainImg}
          />
        </section>
      </div>

      <section className="campus-landing__features">
        <div>
          <h3 className="campus-landing__feature">
            15 thématiques de formation accessibles aux salariés de toutes les
            entreprises adhérentes à Atlas
          </h3>
          <h3 className="campus-landing__feature">
            Des formations délivrées par des organismes de formation
            rigoureusement sélectionnés{" "}
          </h3>
          <h3 className="campus-landing__feature">
            Des formations aux tarifs négociés par Atlas
          </h3>
        </div>
      </section>

      <section className="campus-landing__banner">
        <div className="campus-landing__container">
          <div className="campus-landing__bannerContent">
            <p>
              Grâce à <i>campusAtlas</i>, vous accédez simplement aux formations
              les plus recherchées par les entreprises de votre secteur
              d’activité, et effectuez vos demandes de prise en charge en
              quelques clics.
            </p>
            <p className="campus-landing__bannerHighlight">
              Si votre entreprise relève de la branche des Bureaux d’Études
              Techniques, vous accédez à 20 thématiques de formation
              complémentaires.
            </p>
          </div>
        </div>
      </section>

      <div className="campus-landing__container">
        <section className="campus-landing__part campus-landing__part--decorated">
          <div>

            <CampusHeading tag="h2" heading="Vos prises en charge" />
            <p>
              Tous les modules de formation sélectionnés sur <i>campusAtlas</i>{" "}
              sont accessibles aux entreprises d'Atlas, via un versement
              volontaire. Pour en savoir plus, nous vous invitons à consulter la
              page{" "}
              <a
                href="https://www.opco-atlas.fr/atlas/offre-strategie.html"
                rel="noopener noreferrer"
              >
                Stratégie +
              </a>
              .
            </p>
            <p>
              Certains modules peuvent bénéficier de prise en charge au titre du
              Plan de Développement des Compétences. Rendez-vous dans la
              rubrique « Financer mon projet » du site Atlas pour connaître les
              critères de prise en charge s'appliquant à votre entreprise.
            </p>
            <p>
              Si votre entreprise relève de la branche des Bureaux d’Etudes
              Techniques, vous bénéficiez en outre de prises en charge dédiées
              aux formations référencées sur <i>campusAtlas</i>, au titre des
              financements « Actions collectives ».
            </p>
          </div>
          <img
            aria-hidden="true"
            alt=""
            loading="lazy"
            src={(priseEnChargeImg as unknown as {src: string}).src ?? priseEnChargeImg}
          />
        </section>

        <section className="campus-landing__part campus-landing__part--decorated campus-landing__part--reversed campus-landing__part--steps">
          <div>
            <CampusHeading
              tag="h2"
              heading="En tant qu’employeur, que dois-je faire&nbsp;?"
            />
            <ol>
              <li>
                Choisissez la formation correspondant à votre projet et à celui
                de votre salarié sur campus
              </li>
              <li>
                Faites une demande de pris en charge en cliquant tout simplement
                sur le bouton « Prise en charge » au niveau de la session
                sélectionnée
              </li>
            </ol>
          </div>
          <img
            aria-hidden="true"
            alt=""
            loading="lazy"
            src={(employeurImg as unknown as {src: string}).src ?? employeurImg}
          />
        </section>

        <section className="campus-landing__part campus-landing__part--decorated">
          <div>
            <CampusHeading
              tag="h2"
              heading="En tant que salarié, que dois-je faire&nbsp;?"
            />
            <p>
              Choisissez le programme que vous souhaiteriez suivre et
              partagez-le avec votre manager ou votre service RH.
            </p>
          </div>
          <img
            aria-hidden="true"
            alt=""
            loading="lazy"
            src={(salarieImg as unknown as {src: string}).src ?? salarieImg}
          />
        </section>
      </div>
    </div>
  );
}
