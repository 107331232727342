import { CampusCardList, CampusCardListProps } from "atlas-ds";
import InfiniteScroll from "react-infinite-scroll-component";

export interface CampusInfiniteCardListProps {
  /**
   * La liste de cards
   */
  children: React.ReactElement<CampusCardListProps>;
  /**
   * Le nombre total d'éléments affichables
   */
  totalCount: number;
  /**
   * Le nombre d'éléments effectivement chargés
   */
  loadedCount: number;
  /**
   * L'action à exécuter en fin de scroll
   */
  onScrollEnd: () => void;
}

/**
 * Une liste d'éléments sous forme de cartes, dont la suite peut être chargée
 * au scroll. <i>Note : le chargement des éléments suivants est invisible dans
 * Storybook, le contexte appelant doit les ajouter lui-même dynamiquement.</i>
 */
export function CampusInfiniteCardList(props: CampusInfiniteCardListProps) {
  return (
    <InfiniteScroll
      dataLength={props.loadedCount}
      next={props.onScrollEnd}
      hasMore={props.loadedCount < props.totalCount}
      loader={<></>}
    >
      <CampusCardList
        {...props.children}
        internalFeedTotalCount={props.totalCount}
      >
        {props.children.props.children}
      </CampusCardList>
    </InfiniteScroll>
  );
}
