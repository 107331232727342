import { AtlasIcon, AtlasIconButton, AtlasUserButtonProps } from 'atlas-ds';
import { MouseEventHandler } from "react";

export interface AtlasNavProps {
  /**
   * Le bouton de connexion ou d'ouverture du menu utilisateur
   */
  userButton: React.ReactElement<AtlasUserButtonProps>;
  /**
   * Si le menu de navigation doit présenter un bouton d'ouverture de menu sur
   * petits écrans, l'action à éxécuter au clic
   */
  onMenuOpen?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Indicateur de notification
   */
  notifications?: {
    count: number;
    onClick: MouseEventHandler<HTMLButtonElement>;
  };
}

/**
 * Le menu de navigation Atlas, commun à l'ensemble des sites
 */
export function AtlasNav(props: AtlasNavProps) {
  return (
    <nav className="atlas-nav" role="navigation">
      {props.onMenuOpen && (
        <div className="atlas-nav__menu">
          <AtlasIconButton
            ariaLabel="Menu principal"
            onClick={props.onMenuOpen}
          >
            <AtlasIcon name="menu" />
          </AtlasIconButton>
        </div>
      )}

      <ul className="atlas-nav__links">
        <li className="atlas-nav__link">
          <a
            href="https://www.opco-atlas.fr/actualites.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AtlasIcon name="news" size="xs" />
            Actualités
          </a>
        </li>
        <li className="atlas-nav__link">
          <a
            href="https://www.opco-atlas.fr/agenda.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AtlasIcon name="calendar" size="xs" />
            Agenda
          </a>
        </li>
        {props.notifications && (
          <li>
            <button
              type="button"
              className="atlas-nav__notificationsCta"
              onClick={props.notifications.onClick}
            >
              <AtlasIcon name="comment" size="xs" />
              Notifications
              {props.notifications.count > 0 && (
                <div
                  className="atlas-nav__notificationsCount"
                  aria-label={`${props.notifications.count} notifications`}
                >
                  {props.notifications.count}
                </div>
              )}
            </button>
          </li>
        )}
      </ul>

      {props.userButton}
    </nav>
  );
}
