import { AtlasHtml, AtlasIcon } from "atlas-ds";
import classNames from "classnames";
import React, { MouseEvent, useState } from "react";

export interface AtlasTooltipProps {
  /**
   * Contenu de la tooltip
   */
  content: React.ReactNode;
  /**
   * Description accessible à utiliser à la place de la description générique
   * ("Plus d'information")
   */
  title?: string;
  /**
   * Icône à utiliser à la place de l'icône standard
   */
  iconName?: string;
  /**
   * Si la tooltip accueille un élément enfant, il sera utilisé à la place de
   * l'icône standard. Dans ce cas, l'élément enfant est en charge de la
   * description accessible.
   */
  children?: React.ReactNode;
}

/**
 * Tooltip d'information contextuelle (accompagnant typiquement un label de
 * champ, une valeur...)
 */
export function AtlasTooltip(props: AtlasTooltipProps) {
  const [openBelow, setOpenBelow] = useState(false);
  const [openedWithMouse, setOpenedWithMouse] = useState(false);
  const [usesTouch, setUsesTouch] = useState(false);

  const el = React.createRef<HTMLDetailsElement>(); // <details> element
  const cta = React.createRef<HTMLElement>(); // <summary> element
  const content = React.createRef<HTMLDivElement>(); // Content
  const contentInner = React.createRef<HTMLDivElement>(); // Content inner

  const onClick = (event: MouseEvent) => {
    if (el.current && !el.current.open) {
      event.preventDefault();
      adaptPositionAndOpen(el.current);
    }
  };

  // Triggered when the "open" attribute of the <details> element changes
  const onToggle = () => {
    if (el.current?.open) {
      onOpen();
    } else {
      onClose();
    }
  };

  const onMouseEnter = (event: MouseEvent) => {
    // onMouseEnter also trigger when tapping on mobile, but we don't want that
    // The native behavior of the <details> element will take care of opening
    // the tooltip on mobile
    if (el.current && !el.current.open && !usesTouch) {
      event.preventDefault();
      setOpenedWithMouse(true);

      adaptPositionAndOpen(el.current);
    }
  };

  // Close the tooltip when the mouse leaves
  const onMouseLeave = () => {
    if (el.current) {
      el.current.open = false;
    }
  };

  // Remember that the user uses touch
  const onTouchStart = () => {
    setUsesTouch(true);
  };

  // Close the tooltip on blur (when using tab to go away)
  const onBlur = (event: React.FocusEvent) => {
    if (el.current && !content.current?.contains(event.target as Node)) {
      el.current.open = false;
    }
  };

  const onOpen = () => {
    if (!content.current) return;

    // A11y : set focus on content if it wasnt't opened with the mouse
    if (!openedWithMouse) {
      const elementToFocus =
        content.current.querySelector("a") || content.current;

      elementToFocus.focus();
    } else {
      setOpenedWithMouse(false);
    }

    // Register keyboard events
    document.addEventListener("keydown", onKeydown);
  };

  const onClose = () => {
    // Unregister keyboard events
    document.removeEventListener("keydown", onKeydown);
  };

  // Handle keyboard events
  const onKeydown = (event: KeyboardEvent) => {
    // Close with escape key (accessibility requirement)
    if (event.key === "Escape" && el.current) {
      el.current.open = false;
      cta.current?.focus();
    }
  };

  // Adapt X position (can be done before opening)
  // Then open
  // Adapt Y position (has to wait opening)
  const adaptPositionAndOpen = (details: HTMLDetailsElement) => {
    if (!cta.current || !content.current || !contentInner.current) return;

    const ctaCurrent = cta.current;
    const contentCurrent = content.current;
    const contentInnerCurrent = contentInner.current;

    adaptXPosition(ctaCurrent, contentCurrent, contentInnerCurrent);

    setTimeout(() => {
      details.open = true;

      adaptYPosition(ctaCurrent, contentInnerCurrent);
    });
  };

  // Ensure the tooltip stays inside the screen horizontally
  const adaptXPosition = (
    cta: HTMLElement,
    content: HTMLDivElement,
    contentInner: HTMLDivElement
  ) => {
    // First, find the x position of the center of the cta
    const ctaPosition = cta.getBoundingClientRect();
    const ctaCenter = ctaPosition.left + ctaPosition.width / 2;

    // Get the max width of the tooltip content
    const contentMaxWidth = parseInt(getComputedStyle(contentInner).maxWidth);

    // Estimate where will be the left/right borders of the tooltip content
    // when it opens, based on the center of the cta + the max width of the
    // content
    const projectedContentLeftBorderPosition = ctaCenter - contentMaxWidth / 2;
    const projectedContentRightBorderPosition = ctaCenter + contentMaxWidth / 2;

    // Compute how much the content will overflow
    // If these number are negative, nothing needs to be done
    const leftOverflow = -1 * projectedContentLeftBorderPosition + 8;
    const rightOverflow =
      projectedContentRightBorderPosition - document.body.clientWidth + 8;

    // Adapt the position of the content in case of anticipated overflow
    if (leftOverflow > 0) {
      content.style.setProperty(
        "--atlas-tooltip-translateX",
        `calc(-50% + ${leftOverflow}px)`
      );
    }
    if (rightOverflow > 0) {
      content.style.setProperty(
        "--atlas-tooltip-translateX",
        `calc(-50% - ${rightOverflow}px)`
      );
    }
  };

  // Ensure the tooltip stays inside the screen horizontall vertically
  const adaptYPosition = (cta: HTMLElement, contentInner: HTMLDivElement) => {
    // First, find the y position of the center of the cta
    const ctaPosition = cta.getBoundingClientRect();

    // Get the max width of the tooltip content
    const contentHeight = contentInner.clientHeight;

    // Estimate where will be the top border of the tooltip content
    const safeSpace = 32;
    const projectedContentTopBorderPosition =
      ctaPosition.top - contentHeight - safeSpace;

    // Open below if there is not enough space at the top.
    // This is safe because the max height of the tootlip content is set to 45vh
    setOpenBelow(projectedContentTopBorderPosition < 0);
  };

  return (
    <details
      ref={el}
      className={classNames("atlas-tooltip", {
        "atlas-tooltip--openBelow": openBelow,
      })}
      onToggle={onToggle}
      onClick={onClick}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onTouchStart}
    >
      <summary
        ref={cta}
        className="atlas-tooltip__cta"
        title={
          props.children ? undefined : props.title || "Plus d'informations"
        }
      >
        {props.children || (
          <AtlasIcon size="xs" name={props.iconName || "info"} />
        )}
      </summary>
      <div
        ref={content}
        tabIndex={0}
        onBlur={onBlur}
        className="atlas-tooltip__content"
      >
        <div ref={contentInner} className="atlas-tooltip__inner">
          <AtlasHtml>{props.content}</AtlasHtml>
        </div>
      </div>
    </details>
  );
}
