import {
  AtlasCampusLogo,
  AtlasDetails,
  AtlasIcon,
  AtlasLinkConstructor,
  AtlasOptionsProps,
  defaultLinkConstructor,
} from "atlas-ds";
import { ForwardedRef, MouseEventHandler, forwardRef } from "react";

export interface CampusHeaderProps {
  /**
   * Un constructeur de liens
   */
  linkConstructor?: AtlasLinkConstructor;
  /**
   * Le nom de l'entreprise actuellement active
   */
  currentCompany?: string;
  /**
   * Une liste d'entreprises parmi lesquelles choisir
   */
  companiesList?: React.ReactElement<AtlasOptionsProps>;
  /**
   * L'action à éxecuter lors du retrait de l'entreprise active
   */
  onCompanyClear?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * L'en-tête du site
 */
export const CampusHeader = forwardRef(function CampusHeader(
  props: CampusHeaderProps,
  forwardedRef: ForwardedRef<any>
) {
  const linkConstructor = props.linkConstructor || defaultLinkConstructor;

  return (
    <>
      <header className="campus-header">
        {linkConstructor(
          {
            href: "/",
            className: "campus-header__logo",
            title: "Page d'accueil",
          },
          <AtlasCampusLogo />
        )}

        {props.currentCompany && (
          <>
            {props.companiesList || props.onCompanyClear ? (
              <AtlasDetails
                ref={forwardedRef}
                className="campus-header__company"
              >
                <summary className="campus-header__currentCompany">
                  {props.currentCompany}
                  <AtlasIcon size="xs" name="arrow-down" />
                </summary>

                {props.companiesList ? (
                  <div className="campus-header__companies">
                    {props.companiesList}
                  </div>
                ) : (
                  <button
                    className="campus-header__clearCompany"
                    onClick={props.onCompanyClear}
                  >
                    <AtlasIcon size="xs" name="deconnexion" />
                    Ce n'est pas mon entreprise
                  </button>
                )}
              </AtlasDetails>
            ) : (
              <div className="campus-header__company campus-header__company--unique">
                {props.currentCompany}
              </div>
            )}
          </>
        )}
      </header>
    </>
  );
});
