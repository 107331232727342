import {
  AtlasIcon,
  AtlasIconButton,
  AtlasInfoType,
  AtlasLinkProps,
} from "atlas-ds";
import classNames from "classnames";
import { MouseEventHandler, useEffect, useState } from "react";

export interface AtlasToastsProps {
  /**
   * Les toasts individuels
   */
  children: React.ReactElement<AtlasToastsItemProps>[];
}

/**
 * Les toasts à afficher sur l'application.
 * Tous doivent être inclus dans ce composant global.
 */
export function AtlasToasts(props: AtlasToastsProps) {
  const children = props.children.map((child) => ({
    ...child,
    props: {
      ...child.props,
    },
  }));

  return (
    <div className="atlas-toasts">
      <div className="atlas-toasts__inner">{children}</div>
    </div>
  );
}

export type AtlasToastsItemType = AtlasInfoType;

export interface AtlasToastsItemProps {
  /**
   * Le titre de l'information
   */
  label: string;
  /**
   * Le contenu de l'information
   */
  content: string;
  /**
   * L'action à éxécuter à la fermeture du toast
   */
  onClose: MouseEventHandler<HTMLButtonElement>;
  /**
   * En millisecondes, une durée après laquelle fermer automatiquement le toast
   */
  closeAfterDelay?: number;
  /**
   * Une icône associée à l'information (sinon, une icône par défaut sera
   * utilisée).
   * Note : les icônes ne sont pas supportées sur les toasts larges.
   */
  icon?: string;
  /**
   * Un lien lié à l'information
   */
  link?: React.ReactElement<AtlasLinkProps>;
  /**
   * Le type d'information
   */
  type?: AtlasToastsItemType;
  /**
   * Version plus grande du toast
   */
  large?: boolean;
}

/**
 * Un toast
 */
AtlasToasts.Item = (props: AtlasToastsItemProps) => {
  const link = props.link
    ? {
        ...props.link,
        props: {
          level: 3,
          icon: "arrow-right",
          ...props.link.props,
        },
      }
    : undefined;

  const [isClosing, setIsClosing] = useState(false);

  const onClose = () => {
    setIsClosing(true);
    setTimeout(props.onClose, 250);
  };

  useEffect(() => {
    if (props.closeAfterDelay) {
      const timeout = setTimeout(onClose, props.closeAfterDelay);

      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <div
      className={classNames(
        "atlas-toasts__item",
        `atlas-toasts__item--${props.type ?? "info"}`,
        {
          "atlas-toasts__item--large": props.large,
          "atlas-toasts__item--isClosing": isClosing,
        }
      )}
      role={
        props.type && ["warning", "error"].includes(props.type)
          ? "alert"
          : "status"
      }
    >
      <div className="atlas-toasts__itemInner">
        <AtlasIconButton ariaLabel="Fermer" onClick={onClose}>
          <AtlasIcon name="close" size="s" />
        </AtlasIconButton>
        <header className="atlas-toasts__itemHeader">
          {!props.large && (
            <AtlasIcon name={props.icon ?? props.type ?? "info"} size="xs" />
          )}
          {props.label}
        </header>
        <p className="atlas-toasts__itemContent">{props.content}</p>
        <div onClick={onClose}>{link}</div>
      </div>
    </div>
  );
};
