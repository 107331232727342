import { AtlasCampusLogo, AtlasLinkProps, CampusHeading } from "atlas-ds";

export interface CampusSiteErrorProps {
  /**
   * Le label principal de l'erreur
   */
  label: string;
  /**
   * Le contenu de l'erreur
   */
  content: React.ReactNode;
  /**
   * Un lien
   */
  link: React.ReactElement<AtlasLinkProps>;
}

/**
 * Un message d'erreur global en cas d'indisponibilité du site
 */
export function CampusSiteError(props: CampusSiteErrorProps) {
  return (
    <div className="campus-siteError">
      <div className="campus-siteError__inner">
        <div className="campus-siteError__logo">
          <AtlasCampusLogo />
        </div>

        <CampusHeading tag="h1" heading={props.label} />
        {props.content}
        {props.link}
      </div>
    </div>
  );
}
