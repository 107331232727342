import { AtlasInfoProps, CampusCompanySearchProps, CampusModuleSearchProps } from 'atlas-ds';
import classNames from "classnames";

export type CampusSearchTheme =
  | "branche"
  | "domaine"
  | "thematique"
  | "module"
  | "stage";

export interface CampusSearchProps {
  /**
   * Le formulaire de recherche
   */
  children: React.ReactElement<
    CampusModuleSearchProps | CampusCompanySearchProps
  >;
  /**
   * Type de recherche, utile pour adapter la présentation
   */
  searchType?: "company" | "module";
  /**
   * Le label accessible pour la recherche
   */
  ariaLabel: string;
  /**
   * Le thème visuel (un thème global sera appliqué par défaut)
   */
  theme?: CampusSearchTheme;
  /**
   * Une information contextuelle
   */
  info?: React.ReactElement<AtlasInfoProps>;
}

/**
 * Le conteneur principal pour la recherche
 */
export function CampusSearch(props: CampusSearchProps) {
  return (
    <div
      role="search"
      className={classNames(
        "campus-search",
        `campus-search--${props.searchType}Search`,
        {
          [`campus-search--${props.theme}`]: props.theme,
        }
      )}
      aria-label={props.ariaLabel}
    >
      <div className="campus-search__form">
        <div className="campus-search__formInner">
          {props.info}
          {props.children}
        </div>
      </div>

      <div className="campus-search__image"></div>
    </div>
  );
}
