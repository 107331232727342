import { AtlasIcon, AtlasLink, AtlasLinkConstructor, defaultLinkConstructor } from 'atlas-ds';

export interface CampusFooterLink {
  href: string;
  label: string;
}

export interface CampusFooterProps {
  /**
   * Une liste de clés / valeurs à présenter
   */
  stats: {
    label: string;
    value: string | number;
  }[];
  /**
   * Le lien de contact
   */
  contactLink: CampusFooterLink;
  /**
   * Les liens principaux
   */
  primaryLinks: CampusFooterLink[];
  /**
   * Les liens secondaires
   */
  secondaryLinks: CampusFooterLink[];
  /**
   * Les liens sociaux
   */
  socialLinks: (CampusFooterLink & { icon: string })[];
  /**
   * Les liens d'accessibilité
   */
  accessibilityLinks: CampusFooterLink[];
  /**
   * Une méthode permettant de construire les liens
   */
  linkConstructor?: AtlasLinkConstructor;
}

/**
 * Le footer du site Campus
 */
export function CampusFooter(props: CampusFooterProps) {
  const linkConstructor = props.linkConstructor || defaultLinkConstructor;

  return (
    <footer className="campus-footer">
      <div className="campus-footer__inner">
        <div className="campus-footer__atlas">
          <span>
            <i>campusAtlas</i> est un service appartenant à l'OPCO Atlas
          </span>
        </div>

        <div className="campus-footer__main">
          <div className="campus-footer__contact">
            <h2 className="campus-footer__title">Pour nous contacter</h2>
            <div className="campus-footer__contactLink">
              <AtlasLink external atlasBrand {...props.contactLink} />
            </div>
          </div>
          <div>
            <h2 className="campus-footer__title">Nous suivre</h2>
            <ul className="campus-footer__socialLinks">
              {props.socialLinks.map((link) => (
                <li key={link.label}>
                  {linkConstructor(
                    {
                      href: link.href,
                      title: link.label,
                      rel: "noopener noreferrer",
                      target: "_blank",
                    },
                    <AtlasIcon name={link.icon} />
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h2 className="campus-footer__title">
              <i>campusAtlas</i> c'est
            </h2>
            <div className="campus-footer__stats">
              {props.stats.map((stat) => (
                <div key={stat.label}>
                  <div className="campus-footer__statValue">
                    {stat?.value ?? "-"}
                  </div>
                  <div className="campus-footer__statLabel">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="campus-footer__separator" aria-hidden="true"></div>

        <div className="campus-footer__links">
          <ul className="campus-footer__linksList campus-footer__linksList--primary">
            {props.primaryLinks.map((link) => (
              <li key={link.label}>
                {linkConstructor(
                  { href: link.href },
                  <>
                    <AtlasIcon name="arrow-right" />
                    <span dangerouslySetInnerHTML={{ __html: link.label }} />
                  </>
                )}
              </li>
            ))}
          </ul>
          <ul className="campus-footer__linksList">
            {props.secondaryLinks.map((link) => (
              <li key={link.label}>
                {linkConstructor({ href: link.href }, link.label)}
              </li>
            ))}
          </ul>
          <ul className="campus-footer__linksList campus-footer__linksList--a11y">
            {props.accessibilityLinks.map((link) => (
              <li key={link.label}>
                {linkConstructor({ href: link.href }, link.label)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}
