import { AtlasIcon, AtlasIconButton } from "atlas-ds";
import { MouseEventHandler } from "react";

export interface AtlasTagsProps {
  /**
   * La liste des tags
   */
  children: React.ReactElement<AtlasTagsItemProps>[];
}

/**
 * Une liste de tags
 */
export function AtlasTags(props: AtlasTagsProps) {
  return <div className="atlas-tags">{props.children}</div>;
}

export interface AtlasTagsItemProps {
  /**
   * La valeur du tag
   */
  value: string;
  /**
   * Un label à afficher avant la valeur
   */
  label?: string;
  /**
   * Une action de suppression et sa description
   */
  delete?: {
    onDelete: MouseEventHandler<HTMLButtonElement>;
    ariaLabel: string;
  };
}

AtlasTags.Item = (props: AtlasTagsItemProps) => {
  return (
    <span className="atlas-tags__item">
      {props.label && (
        <span className="atlas-tags__label">{props.label} : </span>
      )}

      <div className="atlas-tags__valueAndDelete">
        <span className="atlas-tags__value">{props.value}</span>

        {props.delete && (
          <div className="atlas-tags__delete">
            <AtlasIconButton
              ariaLabel={props.delete.ariaLabel}
              onClick={props.delete.onDelete}
            >
              <AtlasIcon name="close" size="xs" />
            </AtlasIconButton>
          </div>
        )}
      </div>
    </span>
  );
};

export interface AtlasTagsAddButtonProps {
  /**
   * Le label du bouton
   */
  label: string;
  /**
   * L'action à éxécuter au clic
   */
  onClick: MouseEventHandler<HTMLButtonElement>;
}

AtlasTags.AddButton = (props: AtlasTagsAddButtonProps) => {
  return (
    <button className="atlas-tags__addButton" onClick={props.onClick}>
      <AtlasIcon size="xs" name="plus" />
      {props.label}
    </button>
  );
};
