import { AtlasFieldBaseProps, AtlasFieldInternal } from "../AtlasFieldInternal";

export interface AtlasFieldTimeInputProps {
  id: string;
  name: string;
  type: "number";
  inputMode: "numeric";
  min: number;
  max: number;
  "aria-label": string;
  "aria-invalid"?: "true" | "false";
  "aria-describedby"?: string;
  disabled?: boolean;
  required?: boolean;
}

export interface AtlasFieldTimeProps
  extends Omit<
    AtlasFieldBaseProps<HTMLInputElement, AtlasFieldTimeInputProps>,
    "error"
  > {
  /**
   * Le nom technique du champ heures
   * (note : le "name" original, même s'il n'est associé à aucun champ,
   * reste utile pour la structure du composant)
   */
  nameHours: string;
  /**
   * Le nom technique du champ minutes
   * (note : le "name" original, même s'il n'est associé à aucun champ,
   * reste utile pour la structure du composant)
   */
  nameMinutes: string;
  /**
   * Le nombre minimal d'heures
   */
  minHours?: number;
  /**
   * Le nombre maximal d'heures
   */
  maxHours?: number;
  /**
   * Le nombre minimal de minutes
   */
  minMinutes?: number;
  /**
   * Le nombre maximal de minutes
   */
  maxMinutes?: number;
  /**
   * Une erreur associée au champ heures
   */
  errorHours?: string;
  /**
   * Une erreur associée au champ minutes
   */
  errorMinutes?: string;
}

/**
 * Un double input pour insérer des heures et des minutes
 */
export function AtlasFieldTime(props: AtlasFieldTimeProps) {
  const hoursInputProps: AtlasFieldTimeInputProps = {
    id: props.nameHours,
    name: props.nameHours,
    "aria-label": `${props.label} : heures`,
    "aria-invalid": props.errorHours ? "true" : "false",
    "aria-describedby": props.errorHours
      ? `${props.nameHours}-error`
      : undefined,
    type: "number",
    inputMode: "numeric",
    min: props.minHours ?? 0,
    max: props.maxMinutes ?? 23,
    disabled: props.disabled,
    required: props.required,
  };

  const minutesInputProps: AtlasFieldTimeInputProps = {
    id: props.nameMinutes,
    name: props.nameMinutes,
    "aria-label": `${props.label} : minutes`,
    "aria-invalid": props.errorMinutes ? "true" : "false",
    "aria-describedby": props.errorMinutes
      ? `${props.nameMinutes}-error`
      : undefined,
    type: "number",
    inputMode: "numeric",
    min: props.minMinutes ?? 0,
    max: props.maxMinutes ?? 59,
    disabled: props.disabled,
    required: props.required,
  };

  const hoursInput = props.inputConstructor ? (
    props.inputConstructor(hoursInputProps)
  ) : (
    <input {...hoursInputProps} />
  );

  const minutesInput = props.inputConstructor ? (
    props.inputConstructor(minutesInputProps)
  ) : (
    <input {...minutesInputProps} />
  );

  const error =
    props.errorHours || props.errorMinutes ? (
      <>
        {props.errorHours && (
          <p id={`${props.nameHours}-error`}>Heures : {props.errorHours}</p>
        )}
        {props.errorMinutes && (
          <p id={`${props.nameMinutes}-error`}>
            Minutes : {props.errorMinutes}
          </p>
        )}
      </>
    ) : undefined;

  return (
    <fieldset id={props.name} className="atlas-fieldTime">
      <legend>
        {props.label} {props.instructions}
      </legend>
      <AtlasFieldInternal {...props} isFieldset error={error}>
        <div className="atlas-fieldTime__inputs">
          <div className="atlas-fieldTime__input">{hoursInput}h</div>
          <div className="atlas-fieldTime__input">{minutesInput}min</div>
        </div>
      </AtlasFieldInternal>
    </fieldset>
  );
}
