import {
  AtlasCheckbox,
  AtlasCheckboxProps,
  AtlasFieldBaseProps,
  AtlasFieldInternal,
  AtlasIcon,
} from "atlas-ds";
import { ForwardedRef, forwardRef } from "react";

export type AtlasFieldTextType =
  | "text"
  | "search"
  | "number"
  | "date"
  | "email"
  | "password"
  | "tel"
  | "time";

export type AtlasFieldTextInputMode =
  | "none"
  | "text"
  | "decimal"
  | "numeric"
  | "tel"
  | "search"
  | "email"
  | "url";

export interface AtlasFieldTextInputProps {
  ref: ForwardedRef<any>;
  id: string;
  name: string;
  value?: string;
  type?: AtlasFieldTextType;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: string | number;
  max?: string | number;
  inputMode?: AtlasFieldTextInputMode;
  autoComplete?: string;
  spellCheck?: boolean;
  pattern?: string;
  "aria-invalid"?: "true" | "false";
  "aria-describedby"?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export interface AtlasFieldTextProps
  extends AtlasFieldBaseProps<HTMLInputElement, AtlasFieldTextInputProps> {
  /**
   * La valeur du champ
   */
  value?: string;
  /**
   * Le type du champ (`text` par défaut)
   */
  type?: AtlasFieldTextType;
  /**
   * Un placeholder
   */
  placeholder?: string;
  /**
   * Le nombre de caractères minimal pour le champ
   */
  minLength?: number;
  /**
   * Le nombre de caractères maximal pour le champ
   */
  maxLength?: number;
  /**
   * Un suffixe à ajouter au champ (par exemple, le symbole € pour un coût)
   */
  suffix?: string;
  /**
   * L'information à auto-compléter
   */
  autoComplete?: string;
  /**
   * Une valeur minimale (pour les champs numériques ou de date)
   */
  min?: string | number;
  /**
   * Une valeur maximale (pour les champs numériques ou de date)
   */
  max?: string | number;
  /**
   * Un pattern à respecter pour la validation du champ
   */
  pattern?: string;
  /**
   * Le type de données saisies (principalement pour ajuster le clavier sur
   * mobile)
   */
  inputMode?: AtlasFieldTextInputMode;
  /**
   * Vérifier l'orthographe ? (activé par défaut)
   */
  spellcheck?: boolean;
  /**
   * Ajouter une icône au sein du champ
   */
  icon?: string;
  /**
   * Accompagner le champ d'une checkbox placée au-dessus
   */
  checkbox?: React.ReactElement<AtlasCheckboxProps>;
  /**
   * L'action à exécuter au focus sur ce champ
   */
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  /**
   * L'action à exécuter au focus sur ce champ
   */
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

/**
 * Un champ de saisie de texte
 */
export const AtlasFieldText = forwardRef(function AtlasFieldText(
  props: AtlasFieldTextProps,
  forwardedRef: ForwardedRef<any>
) {
  const inputProps: AtlasFieldTextInputProps = {
    ref: forwardedRef,
    id: props.name,
    name: props.name,
    value: props.value,
    type: props.type,
    placeholder: props.placeholder,
    required: props.required ? true : undefined,
    disabled: props.disabled ? true : undefined,
    minLength: props.minLength,
    maxLength: props.maxLength,
    min: props.min,
    max: props.max,
    pattern: props.pattern,
    inputMode: props.inputMode,
    autoComplete: props.autoComplete,
    spellCheck: props.spellcheck,
    "aria-invalid": props.error ? "true" : undefined,
    "aria-describedby": props.error ? `${props.name}-error` : undefined,
    onChange: props.onChange,
    onFocus: props.onFocus,
    onBlur: props.onBlur,
  };

  const input = props.inputConstructor ? (
    props.inputConstructor(inputProps)
  ) : (
    <input {...inputProps} />
  );

  const checkbox = props.checkbox ? (
    <AtlasCheckbox {...props.checkbox.props} mini />
  ) : undefined;

  return (
    <AtlasFieldInternal {...props}>
      <div className="atlas-fieldText">
        {checkbox && (
          <div className="atlas-fieldText__checkbox">{checkbox}</div>
        )}
        <div className="atlas-fieldText__inner">
          {props.icon && (
            <div className="atlas-fieldText__icon">
              <AtlasIcon size="s" name={props.icon} />
            </div>
          )}
          {input}
          <svg
            className="atlas-fieldText__loader"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path d="M32 16c-0.040-2.089-0.493-4.172-1.331-6.077-0.834-1.906-2.046-3.633-3.533-5.060-1.486-1.428-3.248-2.557-5.156-3.302-1.906-0.748-3.956-1.105-5.981-1.061-2.025 0.040-4.042 0.48-5.885 1.292-1.845 0.809-3.517 1.983-4.898 3.424s-2.474 3.147-3.193 4.994c-0.722 1.846-1.067 3.829-1.023 5.79 0.040 1.961 0.468 3.911 1.254 5.694 0.784 1.784 1.921 3.401 3.316 4.736 1.394 1.336 3.046 2.391 4.832 3.085 1.785 0.697 3.701 1.028 5.598 0.985 1.897-0.040 3.78-0.455 5.502-1.216 1.723-0.759 3.285-1.859 4.574-3.208 1.29-1.348 2.308-2.945 2.977-4.67 0.407-1.046 0.684-2.137 0.829-3.244 0.039 0.002 0.078 0.004 0.118 0.004 1.105 0 2-0.895 2-2 0-0.056-0.003-0.112-0.007-0.167h0.007zM28.822 21.311c-0.733 1.663-1.796 3.169-3.099 4.412s-2.844 2.225-4.508 2.868c-1.663 0.646-3.447 0.952-5.215 0.909-1.769-0.041-3.519-0.429-5.119-1.14-1.602-0.708-3.053-1.734-4.25-2.991s-2.141-2.743-2.76-4.346c-0.621-1.603-0.913-3.319-0.871-5.024 0.041-1.705 0.417-3.388 1.102-4.928 0.683-1.541 1.672-2.937 2.883-4.088s2.642-2.058 4.184-2.652c1.542-0.596 3.192-0.875 4.832-0.833 1.641 0.041 3.257 0.404 4.736 1.064 1.48 0.658 2.82 1.609 3.926 2.774s1.975 2.54 2.543 4.021c0.57 1.481 0.837 3.064 0.794 4.641h0.007c-0.005 0.055-0.007 0.11-0.007 0.167 0 1.032 0.781 1.88 1.784 1.988-0.195 1.088-0.517 2.151-0.962 3.156z"></path>
          </svg>
          {props.suffix && (
            <p className="atlas-fieldText__suffix">{props.suffix}</p>
          )}
        </div>
      </div>
    </AtlasFieldInternal>
  );
});
