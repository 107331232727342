import { AtlasIcon, AtlasLinkProps, CampusHeading } from 'atlas-ds';

export interface CampusIntroProps {
  /**
   * Le titre
   */
  title: string;
  /**
   * La description principale
   */
  description: string;
  /**
   * Le lien pour en savoir plus
   */
  moreLink: React.ReactElement<AtlasLinkProps>;
  /**
   * Les caractéristiques à présenter
   */
  features: {
    icon: string;
    content: React.ReactNode;
  }[];
}

/**
 * Présentation d'introduction du site
 */
export function CampusIntro(props: CampusIntroProps) {
  return (
    <section className="campus-intro">
      <div className="campus-intro__inner">
        <div>
          <CampusHeading tag="h1" heading={`<i>${props.title}</i>`} />
          <p className="campus-intro__text">{props.description}</p>
          {props.moreLink}
        </div>
        <div className="campus-intro__features">
          {props.features.map((feature) => (
            <div className="campus-intro__feature" key={feature.icon}>
              <div className="campus-intro__icon">
                <AtlasIcon name={feature.icon} />
              </div>
              {feature.content}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
