import {
    AtlasIcon,
    AtlasLinkConstructor,
    AtlasLoading,
    AtlasSearchDropdownProps,
    CampusCardTagProps,
    defaultLinkConstructor,
} from "atlas-ds";
import { MouseEventHandler } from "react";

export interface CampusModuleSearchProps {
  /**
   * L'élément de recherche
   */
  searchDropdown: React.ReactElement<AtlasSearchDropdownProps>;
  /**
   * Un chemin vers le catalogue
   */
  catalogueHref: string;
  /**
   * Une méthode de construction du lien
   */
  linkConstructor?: AtlasLinkConstructor;
  /*
   * Le lien de connexion, s'il doit être montré
   */
  loginLink?: React.ReactNode;
}

/**
 * Une interface de recherche de module
 */
export function CampusModuleSearch(props: CampusModuleSearchProps) {
  const linkConstructor = props.linkConstructor || defaultLinkConstructor;

  return (
    <section className="campus-moduleSearch">
      <div className="campus-moduleSearch__inner">
        <form onSubmit={(event) => event.preventDefault()}>
          {props.searchDropdown}
        </form>

        {linkConstructor(
          { href: props.catalogueHref, className: "campus-moduleSearch__cta" },
          <>
            <AtlasIcon size="s" name="catalogue" />
            Ou consulter le catalogue
          </>
        )}
      </div>
      {props.loginLink && (
        <div className="campus-moduleSearch__myAtlas">{props.loginLink}</div>
      )}
    </section>
  );
}

export interface CampusModuleSearchResultsProps {
  /**
   * Les résultats, soit un groupe de thématiques contenant chacune un ou
   * plusieurs modules
   */
  thematiques: {
    id: string;
    href: string;
    label: string;
    modules: {
      id: string;
      href: string;
      label: string;
      tag?: React.ReactElement<CampusCardTagProps>;
    }[];
    tag?: React.ReactElement<CampusCardTagProps>;
  }[];
  /**
   * Au clic sur un résultat
   */
  onClick: MouseEventHandler<HTMLLinkElement>;
  /**
   * Une méthode permettant de construire les liens
   */
  linkConstructor?: AtlasLinkConstructor;
}

/**
 * Les résultats de la recherche de module
 */
CampusModuleSearch.Results = (props: CampusModuleSearchResultsProps) => {
  const linkConstructor = props.linkConstructor || defaultLinkConstructor;

  return (
    <>
      <AtlasLoading.Loaders>
        <div className="campus-moduleSearch__loader">
          <div className="campus-moduleSearch__loaderResult">
            <AtlasLoading.Loader />
            <AtlasLoading.Loader />
          </div>
          <div className="campus-moduleSearch__loaderResult">
            <AtlasLoading.Loader />
            <AtlasLoading.Loader />
          </div>
          <div className="campus-moduleSearch__loaderResult">
            <AtlasLoading.Loader />
            <AtlasLoading.Loader />
          </div>
          <div className="campus-moduleSearch__loaderResult">
            <AtlasLoading.Loader />
            <AtlasLoading.Loader />
          </div>
        </div>
      </AtlasLoading.Loaders>
      <ul className="campus-moduleSearch__results">
        {props.thematiques.map((thematique) => (
          <li key={thematique.id}>
            {linkConstructor(
              {
                href: thematique.href,
                onClick: props.onClick,
                className:
                  "campus-moduleSearch__result campus-moduleSearch__result--thematique",
              },
              <>
                <div className="campus-moduleSearch__icon">
                  <AtlasIcon size="xs" name="thematique" />
                </div>
                {thematique.label}
                {thematique.tag}
              </>
            )}
            <ul>
              {thematique.modules.map((module) => (
                <li key={module.id}>
                  {linkConstructor(
                    {
                      href: module.href,
                      onClick: props.onClick,
                      className:
                        "campus-moduleSearch__result campus-moduleSearch__result--module",
                    },
                    <>
                      <div className="campus-moduleSearch__icon">
                        <AtlasIcon size="xs" name="module" />
                      </div>
                      {module.label}
                      {module.tag}
                    </>
                  )}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </>
  );
};
