import { AtlasIcon, AtlasLinkConstructor, defaultLinkConstructor } from "atlas-ds";
import { CampusCardTagProps } from "../CampusCardTag/CampusCardTag";

export interface CampusThematiqueCardProps {
  /**
   * Le nom de la thématique
   */
  label: string;
  /**
   * Les informations supplémentaires sur la thématique
   */
  infos: {
    value: string | number;
    label: string;
  }[];
  /**
   * Le lien vers la thématique
   */
  href: string;
  /**
   * Si un lien classique ne suffit pas, un constructeur de lien
   */
  linkConstructor?: AtlasLinkConstructor;
  /**
   * Un tag à ajouter à la thématique
   */
  tag?: React.ReactElement<CampusCardTagProps>;
}

/**
 * Une thématique de formation
 */
export function CampusThematiqueCard(props: CampusThematiqueCardProps) {
  const linkConstructor = props.linkConstructor ?? defaultLinkConstructor;

  return (
    <div className="campus-thematiqueCard">
      {linkConstructor(
        {
          href: props.href,
          "aria-label": props.label,
          className: "campus-thematiqueCard__link",
        },
        <>
          <div className="campus-thematiqueCard__icon">
            <AtlasIcon name="thematique" />
          </div>
          <h3 className="campus-thematiqueCard__title">{props.label}</h3>
          {props.infos.map((info) => (
            <div key={info.label}>
              <strong>{info.value}</strong> {info.label}
            </div>
          ))}
        </>
      )}

      {props.tag && (
        <div className="campus-thematiqueCard__tag">{props.tag}</div>
      )}
    </div>
  );
}
