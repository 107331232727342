import {
    AtlasButton,
    AtlasButtonProps,
    AtlasIcon,
    AtlasTooltip,
    AtlasTooltipProps,
    CampusPrices,
    CampusPricesProps,
} from "atlas-ds";
import classNames from "classnames";
import { CampusCardTagProps } from "../CampusCardTag/CampusCardTag";

export interface CampusSessionCardProps {
  /**
   * L'icône associée à la session
   */
  icon: "intra" | "inter";
  /**
   * L'organisme de formation
   */
  organism: string;
  /**
   * Les informations de la session
   */
  infos: {
    icon: string;
    label: string;
  }[];
  /**
   * Le prix de la session
   */
  prices: CampusPricesProps;
  /**
   * Le bouton d'action associé
   */
  button: AtlasButtonProps;
  /**
   * Une tooltip associée au bouton
   */
  tooltip?: AtlasTooltipProps;
  /**
   * Le lien vers le détail
   */
  detailLink?: React.ReactNode;
  /**
   * Le nombre de places
   */
  places?: {
    label: string;
    fewOrNone?: boolean;
  };
  /**
   * Un tag à ajouter à la session
   */
  tag?: React.ReactElement<CampusCardTagProps>;
}

/**
 * Une session de formation
 */
export function CampusSessionCard(props: CampusSessionCardProps) {
  return (
    <div className="campus-sessionCard">
      <div className="campus-sessionCard__mainInfos">
        <AtlasIcon name={props.icon} />
        <h3 className="campus-sessionCard__organism">{props.organism}</h3>

        {props.infos.map((info) => (
          <div className="campus-sessionCard__info" key={info.label}>
            <AtlasIcon size="s" name={info.icon} />
            {info.label}
          </div>
        ))}

        {props.detailLink && (
          <span className="campus-sessionCard__detailLink">
            {props.detailLink}
          </span>
        )}
      </div>

      <div className="campus-sessionCard__pricesAndPlaces">
        <div className="campus-sessionCard__prices">
          <CampusPrices {...props.prices} />
        </div>

        {props.tooltip ? (
          <AtlasTooltip {...props.tooltip}>
            <AtlasButton {...props.button} disabled />
          </AtlasTooltip>
        ) : (
          <AtlasButton {...props.button} />
        )}

        {props.places && (
          <div
            className={classNames("campus-sessionCard__places", {
              "campus-sessionCard__places--fewOrNone": props.places.fewOrNone,
            })}
          >
            <AtlasIcon size="xs" name="tag" />
            {props.places.label}
          </div>
        )}
      </div>

      {props.tag && <div className="campus-sessionCard__tag">{props.tag}</div>}
    </div>
  );
}
